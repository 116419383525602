import { Component, inject, OnInit, signal } from '@angular/core';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { SubscriptionInputFormComponent } from '../../common/subscription-input-form/subscription-input-form.component';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { ISapiSubscriptionUpdate } from '../../../model/subscription-api/sapi-subscription-update';
import { PaymentIdRequestComponent } from '../../common/request/payment-id/payment-id-request.component';
import { AdminNavigationService } from '../../../admin-navigation/admin-navigation.service';

@Component({
    selector: 'update-subscription-by-payment-id',
    imports: [
        ErrorResponseComponent,
        PaymentIdRequestComponent,
        ReqRespTabGroupComponent,
        SubscriptionInputFormComponent,
        SubscriptionOutputFormComponent
    ],
    templateUrl: './update-subscription-by-payment-id.component.html',
    styleUrl: './update-subscription-by-payment-id.component.css'
})
export class UpdateSubscriptionByPaymentIdComponent implements OnInit{

  private adminService = inject(AdminNavigationService);
  private sapi: SubscriptionApiService = inject(SubscriptionApiService);

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);
  errorVisible = signal<boolean>(false);
  processing = signal<boolean>(false);
  request2Disabled = signal<boolean>(false);
  request2Visible = signal<boolean>(false);
  responseDisabled = signal<boolean>(false);
  responseVisible = signal<boolean>(false);
  newSubscription = signal<SapiSubscription | null>(null);
  subscription = signal<SapiSubscription | null>(null);


  ngOnInit(): void {

    this.adminService.titleId.set('update-subscription-by-payment-id.title');
  }
  
  onSubmitPaymentId(paymentId: string): void {
    this.sapi.readByPaymentId(
      paymentId
    ).subscribe(
      {
        next: subscription => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.REQUEST2);
          this.errorVisible.set(false);
          this.request2Disabled.set(false);
          this.request2Visible.set(true);
          this.responseVisible.set(false);
          this.subscription.set(new SapiSubscription(subscription));
          console.log(subscription);
        },
        error: error => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
          console.log(error);
        }
      }
    );
  }

  onUpdate(subscriptionUpdate: ISapiSubscriptionUpdate): void {
    console.log(subscriptionUpdate);

    this.sapi.update(
      this.subscription()?.productId || '',
      this.subscription()?.id || '',
      subscriptionUpdate).subscribe(
        {
          next: subscription => {
            this.processing.set(false);
            this.activeTabIndex.set(ReqRespTab.RESPONSE);
            this.errorVisible.set(false);
            this.responseVisible.set(true);
            this.newSubscription.set(new SapiSubscription(subscription));
            console.log(subscription);
          },
          error: error => {
            this.processing.set(false);
            this.activeTabIndex.set(ReqRespTab.ERROR);
            this.errorVisible.set(true);
            this.responseVisible.set(false);
            this.errorResponse.set(error);
            console.log(error);
          }
        }

      )
  }
}
