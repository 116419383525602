import { Component, computed, effect, inject, OnInit, output, signal } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { IProperties_SubscriptionApi_Server } from '../../../model/properties/properties.interface';
import { PropertiesService } from '../../../service/properties/properties.service';
import { PersistentStateService } from '../../../service/persistent-state/persistent-state.service';
import { Feature } from '../../../service/persistent-state/persistent-state-key';

@Component({
  selector: 'server-select',
  imports: [
    MatSelectModule,
    TranslateModule
  ],
  templateUrl: './server-select.component.html',
  styleUrl: './server-select.component.css'
})

export class ServerSelectComponent implements OnInit {

  //serverSelected = output<IProperties_SubscriptionApi_Server | undefined>();

  persistentState: PersistentStateService = inject(PersistentStateService);
  propertiesService: PropertiesService = inject(PropertiesService);
  sapi: SubscriptionApiService = inject(SubscriptionApiService);

  selectedServerName = signal<string>('');

  serverList: IProperties_SubscriptionApi_Server[] = this.propertiesService.serverList;

  constructor() {

    effect(
      () => this.persistentState.set(
        Feature.SERVER_NAME,
        this.selectedServerName()
      )
    )

  }

  ngOnInit(): void {
    let storedServerName = this.persistentState.get(Feature.SERVER_NAME);
    this.selectedServerName.set(
      storedServerName ?
        storedServerName :
        this.serverList[0].name
    );
  }
}
