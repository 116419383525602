import { Component, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { SapiDevice } from '../../../model/subscription-api/sapi-device';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'device-input-form',
    imports: [
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        TranslateModule
    ],
    templateUrl: './device-input-form.component.html',
    styleUrl: './device-input-form.component.css'
})
export class DeviceInputFormComponent {

  device  = model<SapiDevice>(SapiDevice.dummyDevice());
}
