<div class="form-container">
    <form #findByEmailForm="ngForm" (ngSubmit)="onSubmit()" [class.processing]="processing()">

        <server-select></server-select>

        <product-select (productSelected)="onProductSelected($event)"></product-select>

        <email-input [(email)]="email" [(isValid)]="emailValid"></email-input>

        <button type="submit" mat-raised-button class="find-button"
            [disabled]="!findByEmailForm.form.valid || !emailValid() || processing()">
            <mat-icon>search</mat-icon>
            {{'button.find' | translate}}
        </button>

    </form>

    @if(processing()) {
        <mat-spinner class="form-spinner" diameter="80"></mat-spinner>
    }
</div>