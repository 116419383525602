import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'delete-subscription-confirm',
    imports: [
        MatButtonModule,
        MatIconModule,
        SubscriptionOutputFormComponent,
        TranslateModule
    ],
    templateUrl: './delete-subscription-confirm.component.html',
    styleUrl: './delete-subscription-confirm.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteSubscriptionConfirmComponent {

  subscription = input<SapiSubscription | null>(null);
  delete = output<string | null>();

  onDelete() {
    this.delete.emit(this.subscription()?.id || null);
  }

}
