<mat-form-field>
    <mat-label>{{'server_select.server_label' | translate}}</mat-label>
    <mat-select [(value)]="selectedServerName">
        @for (server of serverList; track server.url) {

        <mat-option value="{{server.name}}">{{server.name}}</mat-option>
        }
    </mat-select>
</mat-form-field>
@if (selectedServerName().length == 0) {
<mat-error>{{'server_select.server_required' | translate}}</mat-error>
}
