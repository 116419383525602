import { Component, inject, OnInit, signal } from '@angular/core';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { EmailRequestComponent } from '../../common/request/email-request/email-request.component';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { IEmailRequest } from '../../../model/request/i-email.request';
import { SubscriptionListComponent } from '../../common/subscription-list-output-form/subscription-list/subscription-list.component';
import { ISubscriptionUpdateEvent } from '../../../model/event/i-subscription-update.event';
import { AdminNavigationService } from '../../../admin-navigation/admin-navigation.service';

@Component({
    selector: 'update-subscription-by-email',
    imports: [
        ErrorResponseComponent,
        EmailRequestComponent,
        ReqRespTabGroupComponent,
        SubscriptionListComponent,
        SubscriptionOutputFormComponent
    ],
    templateUrl: './update-subscription-by-email.component.html',
    styleUrl: './update-subscription-by-email.component.css'
})
export class UpdateSubscriptionByEmailComponent implements OnInit{

  private adminService = inject(AdminNavigationService);
  private sapi: SubscriptionApiService = inject(SubscriptionApiService);

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);
  errorVisible = signal<boolean>(false);
  processing =  signal<boolean>(false);
  request2Disabled = signal<boolean>(false);
  request2Visible = signal<boolean>(false);
  responseDisabled = signal<boolean>(false);
  responseVisible = signal<boolean>(false);
  newSubscription = signal<SapiSubscription | null>(null);
  subscriptions = signal<SapiSubscription[]>([]);

  ngOnInit(): void {

    this.adminService.titleId.set('update-subscription-by-email.title');
  }
  
  onSubmitEmail(params: IEmailRequest): void {
    this.sapi.readByEmail(
      params.productId,
      params.email
    ).subscribe(
      {
        next: subscriptions => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.REQUEST2);
          this.errorVisible.set(false);
          this.request2Disabled.set(false);
          this.request2Visible.set(true);
          this.subscriptions.set(
            subscriptions.map(
              iSubscription =>
                new SapiSubscription(iSubscription)
            )
          );
          console.log(subscriptions);
        },
        error: error => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
          console.log(error);
        }
      }
    );
  }

  onUpdate(subscriptionUpdateEvent: ISubscriptionUpdateEvent): void {
    console.log(subscriptionUpdateEvent);

    this.sapi.update(
      subscriptionUpdateEvent.productId,
      subscriptionUpdateEvent.subscriptionId,
      subscriptionUpdateEvent.subscriptionUpdate
    ).subscribe(
      {
        next: subscription => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.RESPONSE);
          this.errorVisible.set(false);
          this.responseVisible.set(true);
          this.newSubscription.set(new SapiSubscription(subscription));
          console.log(subscription);
        },
        error: error => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
          console.log(error);
        }
      }

    )
  }
}
