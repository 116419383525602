import { Component, computed, input } from '@angular/core';
import { OutputFormRowComponent } from '../output-form-row/output-form-row.component';
import { SapiAddress } from '../../../model/subscription-api/sapi-address';

@Component({
    selector: 'address-output-form',
    imports: [
        OutputFormRowComponent
    ],
    templateUrl: './address-output-form.component.html',
    styleUrl: './address-output-form.component.css'
})
export class AddressOutputFormComponent {

  address = input<SapiAddress | null>(null)

  city = computed(() => this.address()?.city || '');
  company = computed(() => this.address()?.company || '');
  country = computed(() => this.address()?.country || '');
  firstName = computed(() => this.address()?.firstName || '');
  lastName = computed(() => this.address()?.lastName || '');
  street = computed(() => this.address()?.street || '');
  zip = computed(() => this.address()?.zip || '');

}
