import { Component, inject, OnInit, signal } from '@angular/core';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { UserIdRequestComponent } from '../../common/request/user-id/user-id-request.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RouterUrl } from '../../../model/enum/router-url.enum';
import { AdminNavigationService } from '../../../admin-navigation/admin-navigation.service';

@Component({
    selector: 'read-subscription-by-user-id',
    imports: [
        ErrorResponseComponent,
        MatIconModule,
        MatButtonModule,
        UserIdRequestComponent,
        ReqRespTabGroupComponent,
        SubscriptionOutputFormComponent,
        TranslateModule
    ],
    templateUrl: './read-subscription-by-user-id.component.html',
    styleUrl: './read-subscription-by-user-id.component.css'
})
export class ReadSubscriptionByUserIdComponent implements OnInit{

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);
  errorVisible = signal<boolean>(false);
  processing = signal<boolean>(false);
  request2Disabled = signal<boolean>(true);
  request2Visible = signal<boolean>(false);
  responseDisabled = signal<boolean>(true);
  responseVisible = signal<boolean>(false);
  subscription = signal<SapiSubscription | null>(null);

  private adminService = inject(AdminNavigationService);
  private router: Router = inject(Router);
  private sapi: SubscriptionApiService = inject(SubscriptionApiService);

  ngOnInit(): void {

    this.adminService.titleId.set('read-subscription-by-user-id.title');
  }
  
  onDelete(): void {

    this.router.navigate(
      [
        RouterUrl.DELETE_BY_ID,
        {
          subscriptionId: this.subscription()?.id
        }
      ]
    );
  }

  onSubmit(userId: string): void {
    this.sapi.readByUserId(userId).subscribe(
      {
        next: subscription => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.RESPONSE);
          this.errorVisible.set(false);
          this.responseDisabled.set(false);
          this.responseVisible.set(true);
          this.subscription.set(new SapiSubscription(subscription));
          console.log(subscription);
        },
        error: error => {
          this.processing.set(false);
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
          console.log(error);
        }
      }
    );

  }

  onUpdate(): void {

    this.router.navigate(
      [
        RouterUrl.UPDATE_BY_ID,
        {
          subscriptionId: this.subscription()?.id
        }
      ]
    );
  }


}
