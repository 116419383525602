<div class="form-container">
    <form #readByUserIdForm="ngForm" (ngSubmit)="onSubmit()" [class.processing]="processing()">

        <server-select></server-select>

        <mat-form-field>
            <mat-label>{{'login.user_id_label' | translate}}</mat-label>

            <input name="user-id" matInput [(ngModel)]="userId" required #user_id="ngModel">

            @if (userId().length > 0) {
            <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="userId.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }

            @if((user_id.dirty || user_id.touched) &&
            (user_id.invalid && user_id.errors?.['required'])
            ) {
            <mat-error>{{'login.user_id_required' | translate}}</mat-error>
            }

        </mat-form-field>

        <button type="submit" mat-raised-button class="find-button" [disabled]="!readByUserIdForm.form.valid || processing()">
            <mat-icon>search</mat-icon>
            {{'button.find' | translate}}
        </button>
    </form>

    @if(processing()) {
        <mat-spinner class="form-spinner" diameter="80"></mat-spinner>
    }
</div>