import { Component } from '@angular/core';

@Component({
    selector: 'create-subscription-multiple-response',
    imports: [],
    templateUrl: './create-subscription-multiple-response.component.html',
    styleUrl: './create-subscription-multiple-response.component.css'
})
export class CreateSubscriptionMultipleResponseComponent {

}
