import { Component, model, output, signal } from '@angular/core';
import { ProductSelectComponent } from '../../product-select/product-select.component';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { EmailInputComponent } from '../../email-input/email-input.component';
import { IEmailRequest } from '../../../../model/request/i-email.request';
import { MatIconModule } from '@angular/material/icon';
import { ServerSelectComponent } from "../../server-select/server-select.component";
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'email-request',
  imports: [
    EmailInputComponent,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinner,
    ProductSelectComponent,
    ServerSelectComponent,
    TranslateModule
  ],
  templateUrl: './email-request.component.html',
  styleUrl: './email-request.component.css'
})
export class EmailRequestComponent {
  
  processing = model(false); // Tracks whether the request is in progress.
  submitRequest = output<IEmailRequest>();

  email = signal<string>('');
  emailValid = signal<boolean>(false);
  productId = signal<string>('');

  onProductSelected(product: ISapiProduct | undefined) {
    if (product) {
      this.productId.set(product.id);
    }
  }

  onSubmit(): void {

    if (this.processing()) return;

    this.processing.set(true); // Disable inputs and show the spinner.

    this.submitRequest.emit(
      {
        productId: this.productId(),
        email: this.email().trim()
      }
    );
  }


}
