import { Component, effect, input, model, signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { SapiAddress } from '../../../model/subscription-api/sapi-address';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'address-input-form',
    imports: [
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        TranslateModule
    ],
    templateUrl: './address-input-form.component.html',
    styleUrl: './address-input-form.component.css'
})
export class AddressInputFormComponent {

  address  = model<SapiAddress>(SapiAddress.dummyAddress());

}
