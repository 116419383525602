import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-alert-dialog',
    imports: [
        MatButtonModule,
        TranslateModule
    ],
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  message: SafeHtml;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AlertDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private params: AlertDialogParams

  ) {
    this.message = params.message;
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.message =
      this.sanitizer.bypassSecurityTrustHtml(
        this.params.message
      );
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

}

export class AlertDialogParams {

  message: string = '';
  message2: string = '';
}
