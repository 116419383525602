import { Component, computed, effect, inject, input, model, output, signal } from '@angular/core';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { SapiDeviceType } from '../../../model/subscription-api/enum/sapi-device-type.enum';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddressInputFormComponent } from '../address-input-form/address-input-form.component';
import { SapiAddress } from '../../../model/subscription-api/sapi-address';
import { DeviceInputFormComponent } from '../device-input-form/device-input-form.component';
import { SapiDevice } from '../../../model/subscription-api/sapi-device';
import { CommonModule } from '@angular/common';
import { ISapiSubscriptionUpdate } from '../../../model/subscription-api/sapi-subscription-update';
import { RouterUrl } from '../../../model/enum/router-url.enum';
import { Router } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'subscription-input-form',
    imports: [
        CommonModule,
        AddressInputFormComponent,
        DeviceInputFormComponent,
        FormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinner,
        TranslateModule
    ],
    templateUrl: './subscription-input-form.component.html',
    styleUrl: './subscription-input-form.component.css'
})
export class SubscriptionInputFormComponent {

  processing = model(false); // Tracks whether the request is in progress.
  update = output<ISapiSubscriptionUpdate>();

  private router: Router = inject(Router);
  sapi: SubscriptionApiService = inject(SubscriptionApiService);

  subscription = input<SapiSubscription | null>(null);

  subscriptionUpdate = effect(
    () => {
      if (this.subscription()) {
        this.address.set(this.subscription()?.address || SapiAddress.dummyAddress());
        this.desktopDevice.set(this.subscription()?.getDevice(SapiDeviceType.DESKTOP) || SapiDevice.dummyDevice());
        this.email.set(this.subscription()?.email || '');
        this.length.set(this.subscription()?.length || 0);
        this.mobileDevice.set(this.subscription()?.getDevice(SapiDeviceType.MOBILE) || SapiDevice.dummyDevice());
        this.password.set(this.subscription()?.password || '');
        this.startDate.set(this.subscription()?.startDate || null);
        this.userId.set(this.subscription()?.userId || '');
      }
    }
  );


  address = signal(SapiAddress.dummyAddress());
  desktopDevice = signal(SapiDevice.dummyDevice());
  email = signal('');
  length = signal(0);
  mobileDevice = signal(SapiDevice.dummyDevice());
  password = signal('');
  startDate = signal<Date | null>(null);
  userId = signal('');

  creationDate = computed(
    () => new Date(this.subscription()?.creationDate || '')
      .toLocaleDateString('de-CH', { day: '2-digit', month: '2-digit', year: 'numeric' }));

  expirationDate = computed(() => this.subscription()?.expirationDate?.toLocaleString('de-CH') || '');
  language = computed(() => this.subscription()?.language || '');
  lastAccessTime = computed(
    () => new Date(this.subscription()?.lastAccessTime || '')
      .toLocaleString(
        'de-CH',
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }
      )
  );
  paymentId = computed(() => this.subscription()?.paymentId || '');
  product = computed(() => this.products.get(this.subscription()?.productId || '') || '');

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }


  private readonly products: Map<string, string> = new Map();

  constructor() {
    this.sapi.readProducts().subscribe(
      products => {
        products.forEach(
          product => {
            this.products.set(product.id, product.name);
          }
        );
      }
    );
  }

  onDelete(): void {

    this.router.navigate(
      [
        RouterUrl.DELETE_BY_ID,
        {
          subscriptionId: this.subscription()?.id
        }
      ]
    );
  }

  onSubmit(): void {
    if (this.processing()) return;

    this.processing.set(true); // Disable inputs and show the spinner.

    this.update.emit(
      {
        startDate: this.startDate(),
        length: this.length(),
        userId: this.userId(),
        password: this.password(),
        email: this.email(),
        paymentId: this.paymentId(),
        address: this.address(),
        devices: [this.desktopDevice(), this.mobileDevice()]
      }
    );
  }
}
