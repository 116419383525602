<div class="form-container">
    <form #subscriptionIdForm="ngForm"  [class.processing]="processing()" (ngSubmit)="onSubmit()">

        <server-select></server-select>

        <mat-form-field>
            <mat-label>{{'subscription_id_request.label' | translate}}</mat-label>

            <input name="payment-id" matInput [(ngModel)]="subscriptionId" #payment_id="ngModel" required>

            @if (subscriptionId().length > 0) {
            <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="subscriptionId.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }

            @if((payment_id.dirty || payment_id.touched) &&
            (payment_id.invalid && payment_id.errors?.['required'])
            ) {
            <mat-error>{{'subscription_id_request.required' | translate}}</mat-error>
            }

        </mat-form-field>

        <button type="submit" mat-raised-button class="find-button" [disabled]="!subscriptionIdForm.form.valid || processing()">
            <mat-icon>search</mat-icon>
            {{'subscription_id_request.find' | translate}}
        </button>
    </form>

    @if(processing()) {
        <mat-spinner class="form-spinner" diameter="80"></mat-spinner>
    }
</div>