@if(subscription()) {
<div class="subscription-container">
    <output-form-row [labelId]="'subscription.id'" [value]="subscriptionId()"></output-form-row>
    <output-form-row [labelId]="'subscription.product'" [value]="product()"></output-form-row>
    <output-form-row [labelId]="'subscription.payment_id'" [value]="paymentId()"></output-form-row>
    <output-form-row [labelId]="'subscription.user_id'" [value]="userId()" [copyValue]="true"></output-form-row>
    <output-form-row [labelId]="'subscription.password'" [value]="password()" [copyValue]="true"></output-form-row>
    <output-form-row [labelId]="'subscription.email'" [value]="email()" [copyValue]="true"></output-form-row>
    <output-form-row [labelId]="'subscription.language'" [value]="language()"></output-form-row>
    <output-form-row [labelId]="'subscription.creation_date'" [value]="creationDate()"></output-form-row>
    <output-form-row [labelId]="'subscription.start_date'" [value]="startDate()"></output-form-row>
    <output-form-row [labelId]="'subscription.length'" [value]="length()"></output-form-row>
    <output-form-row [labelId]="'subscription.expiration_date'" [value]="expirationDate()"></output-form-row>
    <output-form-row [labelId]="'subscription.last_access_time'" [value]="lastAccessTime()"></output-form-row>

    <mat-accordion class="accordion-headers-align">
        <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>{{'address_panel.title' | translate}}</mat-panel-title>
                <mat-panel-description>
                    {{'address_panel.description' | translate}}
                    <mat-icon>home</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <address-output-form [address]="address()"></address-output-form>

            <mat-action-row>
                <button mat-button (click)="nextStep()">Next</button>
            </mat-action-row>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>{{'device_panel.device_1' | translate}}</mat-panel-title>
                <mat-panel-description>
                    {{'device_panel.mobile' | translate}}
                    <mat-icon>smartphone</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <device-output-form [device]="mobileDevice()"></device-output-form>

            <mat-action-row>
                <button mat-button (click)="prevStep()">Previous</button>
                <button mat-button (click)="nextStep()">Next</button>
            </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>{{'device_panel.device_2' | translate}}</mat-panel-title>
                <mat-panel-description>
                    {{'device_panel.desktop' | translate}}
                    <mat-icon>computer</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <device-output-form [device]="desktopDevice()"></device-output-form>

            <mat-action-row>
                <button mat-button (click)="prevStep()">Previous</button>
                <button mat-button (click)="nextStep()">End</button>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</div>
}