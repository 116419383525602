import { Component, inject, OnInit } from '@angular/core';
import { ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { CreateSubscriptionMultipleRequestComponent } from './request/create-subscription-multiple-request.component';
import { CreateSubscriptionMultipleResponseComponent } from './response/create-subscription-multiple-response.component';
import { AdminNavigationService } from '../../../admin-navigation/admin-navigation.service';

@Component({
    selector: 'create-subscription-multiple',
    imports: [
        CreateSubscriptionMultipleRequestComponent,
        CreateSubscriptionMultipleResponseComponent,
        ReqRespTabGroupComponent
    ],
    templateUrl: './create-subscription-multiple.component.html',
    styleUrl: './create-subscription-multiple.component.css'
})
export class CreateSubscriptionMultipleComponent implements OnInit{

  private adminService = inject(AdminNavigationService);

  ngOnInit(): void {

    this.adminService.titleId.set('create-subscription-multiple.title');
  }

}
