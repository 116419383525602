import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { IProperties, IProperties_Authentication, IProperties_SubscriptionApi, IProperties_SubscriptionApi_Server } from "../../model/properties/properties.interface";
import { GlobalConst } from "../../utils/global-const";
import { Observable, ReplaySubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PropertiesService {

    private readonly http: HttpClient = inject(HttpClient);

    private properties: IProperties = {} as IProperties;

    private loadComplete$$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    public loadComplete$: Observable<boolean> = this.loadComplete$$.asObservable();

    get authentication(): IProperties_Authentication {
        return this.properties.authentication;
    }

    get httpRequestRetryCount(): number {
        return this.properties.httpRequestRetryCount;
    }

    get serverList(): IProperties_SubscriptionApi_Server[] {
        return this.properties.subscriptionApi.serverList;
    }

    get subscriptionApi(): IProperties_SubscriptionApi {
        return this.properties.subscriptionApi;
    }

    public load(): Promise<boolean> {

        return new Promise(
            (resolve, reject) => {

                this.http.get<IProperties>(GlobalConst.PROPERTIES_URL)
                    .subscribe(
                        {
                            next: properties => {
                                this.properties = properties;
                                this.loadComplete$$.next(true);
                                resolve(true);
                            },
                            error: error => {
                                console.log('Properties load error: ', error);
                                reject(error);
                            }
                        }
                    );
            }
        );
    }

}
