import { ClipboardModule } from '@angular/cdk/clipboard';
import { Component, computed, effect, inject, input, OnInit, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'output-form-row',
  imports: [
    ClipboardModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './output-form-row.component.html',
  styleUrl: './output-form-row.component.css'
})
export class OutputFormRowComponent {

  translate = inject(TranslateService);

  static labelWidth = signal(1);

  copyValue = input(false);
  labelId = input('');
  value = input('');

  labelWidth = computed<string>(
    () => OutputFormRowComponent.labelWidth() + 'ch'
  );

  label: string = '';

  ngOnInit(): void {
    this.label = this.translate.instant(this.labelId());
    OutputFormRowComponent.labelWidth.update(
      value =>
        this.label.length > value ? this.label.length : value
    );
  }
}
