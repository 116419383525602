import { inject, Injectable } from "@angular/core";
import { SapiParam } from "../../model/subscription-api/enum/sapi-param.enum";
import { PropertiesService } from "../properties/properties.service";
import { IProperties_SubscriptionApi_Server } from "../../model/properties/properties.interface";
import { PersistentStateService } from "../persistent-state/persistent-state.service";
import { Feature } from "../persistent-state/persistent-state-key";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionApiUrlService {

    private readonly persistentState: PersistentStateService = inject(PersistentStateService);
    private readonly properties: PropertiesService = inject(PropertiesService);

    private readonly createQuery: string;
    private readonly readQuery: string;
    private readonly readByEmailQuery: string;
    private readonly readByPaymentIdQuery: string;
    private readonly readByUserIdQuery: string;
    private readonly readProductsQuery: string;
    private readonly updateQuery: string;
    private readonly deleteQuery: string;

    private baseUrl: string;

    constructor() {
        this.createQuery = this.properties
            .subscriptionApi
            .query
            .create;

        this.readQuery = this.properties
            .subscriptionApi
            .query
            .read;

        this.readByEmailQuery = this.properties
            .subscriptionApi
            .query
            .readByEmail;

        this.readByPaymentIdQuery = this.properties
            .subscriptionApi
            .query
            .readByPaymentId;


        this.readByUserIdQuery = this.properties
            .subscriptionApi
            .query
            .readByUserId;

        this.readProductsQuery = this.properties
            .subscriptionApi
            .query
            .readProducts;

        this.updateQuery = this.properties
            .subscriptionApi
            .query
            .update;

        this.deleteQuery = this.properties
            .subscriptionApi
            .query
            .delete;

        this.baseUrl = this.buildBaseUrl();
        this.persistentState.valueChanged$
            .pipe(
                filter(valueName => valueName == Feature.SERVER_NAME),
                takeUntilDestroyed()
            ).subscribe(
                () => this.baseUrl = this.buildBaseUrl()
            );
    }

    public getCreateUrl(
        productId: string
    ): string {

        return this.baseUrl.concat(
            this.createQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
        );
    }


    public getReadUrl(
        subscriptionId: string
    ): string {

        return this.baseUrl.concat(
            this.readQuery
                .replace(
                    SapiParam.SUSBCRIPTION_ID,
                    subscriptionId
                )
        );
    }

    public getReadByEmailUrl(
        productId: string,
        email: string
    ): string {

        return this.baseUrl.concat(
            this.readByEmailQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
                .replace(
                    SapiParam.EMAIL,
                    email
                )
        );
    }

    public getReadByPaymentIdUrl(
        paymentId: string
    ): string {

        return this.baseUrl.concat(
            this.readByPaymentIdQuery
                .replace(
                    SapiParam.PAYMENT_ID,
                    paymentId
                )
        );
    }


    public getReadByUserIdUrl(userId: string): string {

        return this.baseUrl.concat(
            this.readByUserIdQuery
                .replace(
                    SapiParam.USER_ID,
                    userId
                )
        );
    }

    public getReadProductsUrl(): string {

        return this.baseUrl
            .concat(this.readProductsQuery);
    }

    public getUpdateUrl(
        productId: string,
        subscriptionId: string
    ): string {

        return this.baseUrl.concat(
            this.updateQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
                .replace(
                    SapiParam.SUSBCRIPTION_ID,
                    subscriptionId
                )
        );
    }

    public getDeleteUrl(subscriptionId: string): string {

        return this.baseUrl.concat(
            this.deleteQuery.replace(
                SapiParam.SUSBCRIPTION_ID,
                subscriptionId
            )
        );
    }

    private buildBaseUrl(): string {
        // Get the list of servers
        let serverList: IProperties_SubscriptionApi_Server[] =
            this.properties.subscriptionApi.serverList;
        // Get the stored server name 
        let storedServerName: string =
            this.persistentState.get(Feature.SERVER_NAME);

        // If the stored server name is not found, return the first server's URL
        let serverName = storedServerName ?? serverList[0].name;

        return serverList.find(server => server.name === serverName)?.url ?? '';
    }
}

