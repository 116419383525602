

export class PersistentStateKey {

  private static map: any;

  static get(feature: string): string {
    if (PersistentStateKey.map == null) {
      PersistentStateKey._initialize();
    }
    return PersistentStateKey.map.get(feature);
  }

  private static _initialize(): void {
    PersistentStateKey.map = new Map<string, string>();
    PersistentStateKey.map.set(Feature.LOGIN_TIME, Feature.LOGIN_TIME);
    PersistentStateKey.map.set(Feature.SERVER_NAME, Feature.SERVER_NAME);
  }
}

export enum Feature {
  /* Anonymous keys */
  LOGIN_TIME = 'x001',
  SERVER_NAME = 'x002'
}