import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertDialogService } from '../../common/alert-dialog/alert-dialog';
import { AuthService } from '../../../service/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'login',
    imports: [
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        TranslateModule
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.css'
})
export class LoginComponent {

  private readonly alertDialog: AlertDialogService = inject(AlertDialogService);
  private readonly auth: AuthService = inject(AuthService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private readonly translate: TranslateService = inject(TranslateService);

  hidePassword = signal<boolean>(true);
  userId = signal<string>('');
  password = signal<string>('');

  private returnUrl: string = '/';

  constructor() {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';
    });
  }

  onSubmit(): void {
    if (
      this.auth.login(
        this.userId(),
        this.password()
      )
    ) {
      //this.alertDialog.show('Login successful!');
      this.router.navigate([this.returnUrl],{relativeTo: null});
    } else {
      this.alertDialog.show(
        this.translate.instant('login.login_failed')
      );
    }
  }

  togglePasswordVisibility(): void {
    this.hidePassword.update((value) => !value);
  }
}
