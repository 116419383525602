import { Component, input, model } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

export enum ReqRespTab{
  REQUEST = 0,
  REQUEST2 = 1,
  RESPONSE = 2,
  ERROR = 3
}

@Component({
    selector: 'req-resp-tab-group',
    imports: [
        MatIcon,
        MatTabsModule,
        TranslateModule
    ],
    templateUrl: './req-resp-tab-group.component.html',
    styleUrl: './req-resp-tab-group.component.css'
})
export class ReqRespTabGroupComponent {

  errorVisible = input<boolean>(false);
  request2Disabled = input<boolean>(true);
  request2Visible = input<boolean>(true);
  responseDisabled = input<boolean>(true);
  responseVisible = input<boolean>(true);
  
  activeTabIndex = model(ReqRespTab.REQUEST);
  
  activeLink: string = 'request';
}
