import { Component, model, output, signal } from '@angular/core';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ServerSelectComponent } from "../../server-select/server-select.component";
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'payment-id-request',
    imports: [
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinner,
    ServerSelectComponent,
    TranslateModule
],
    templateUrl: './payment-id-request.component.html',
    styleUrl: './payment-id-request.component.css'
})
export class PaymentIdRequestComponent {

  processing = model(false); // Tracks whether the request is in progress.
  submitRequest = output<string>();

  paymentId = signal<string>('');

  onSubmit(): void {
    
    if (this.processing()) return;

    this.processing.set(true); // Disable inputs and show the spinner.

    this.submitRequest.emit(this.paymentId().trim());
  }

}
