import { Component, computed, inject, input, signal } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { MatInputModule } from '@angular/material/input';
import { OutputFormRowComponent } from '../output-form-row/output-form-row.component';
import { MatButtonModule } from '@angular/material/button';
import { AddressOutputFormComponent } from '../address-output-form/address-output-form.component';
import { SapiDeviceType } from '../../../model/subscription-api/enum/sapi-device-type.enum';
import { DeviceOutputFormComponent } from "../device-output-form/device-output-form.component";

@Component({
    selector: 'subscription-output-form',
    imports: [
        AddressOutputFormComponent,
        DeviceOutputFormComponent,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        OutputFormRowComponent,
        TranslateModule,
        DeviceOutputFormComponent
    ],
    templateUrl: './subscription-output-form.component.html',
    styleUrl: './subscription-output-form.component.css'
})
export class SubscriptionOutputFormComponent {

  sapi: SubscriptionApiService = inject(SubscriptionApiService);

  subscription = input<SapiSubscription | null>(null);

  address = computed(() => this.subscription()?.address || null);
  creationDate = computed(
    () => new Date(this.subscription()?.creationDate || '')
      .toLocaleDateString('de-CH', { day: '2-digit', month: '2-digit', year: 'numeric' }));

  desktopDevice = computed(() => this.subscription()?.getDevice(SapiDeviceType.DESKTOP) || null);
  email = computed(() => this.subscription()?.email || '');
  expirationDate = computed(() => this.subscription()?.expirationDate?.toLocaleString('de-CH') || '');
  language = computed(() => this.subscription()?.language || '');
  lastAccessTime = computed(
    () => new Date(this.subscription()?.lastAccessTime || '')
      .toLocaleString(
        'de-CH',
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }
      )
  );
  length = computed(() => this.subscription()?.length.toString() || '');
  mobileDevice = computed(() => this.subscription()?.getDevice(SapiDeviceType.MOBILE) || null);
  password = computed(() => this.subscription()?.password || '');
  paymentId = computed(() => this.subscription()?.paymentId || '');
  product = computed(() => this.products.get(this.subscription()?.productId || '') || '');
  startDate = computed(() => this.subscription()?.startDate !== null ?
    new Date(this.subscription()?.startDate || '').toLocaleString('de-CH', { day: '2-digit', month: '2-digit', year: 'numeric' })
    : ''
  );
  subscriptionId = computed(() => this.subscription()?.id || '');
  userId = computed(() => this.subscription()?.userId || '');

  step = signal(-1);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }


  private readonly products: Map<string, string> = new Map();

  constructor() {
    this.sapi.readProducts().subscribe(
      products => {
        products.forEach(
          product => {
            this.products.set(product.id, product.name);
          }
        );
      }
    );
  }
}
