import { Injectable } from '@angular/core';
import { Feature, PersistentStateKey } from './persistent-state-key';
import { Observable, Subject } from 'rxjs';


/**
 * The service is used to 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Injectable({
  providedIn: 'root'
})
export class PersistentStateService {

  private _map: Map<Feature, any> = new Map<Feature, any>();;
  private valueChanged$$: Subject<Feature> = new Subject<Feature>();

  delete(feature: Feature): void {

    this._map.delete(feature);

    localStorage.removeItem(
      PersistentStateKey.get(feature)
    );
  }

  has(feature: Feature): boolean {

    return this._map.has(feature) ?
      true :
      localStorage[PersistentStateKey.get(feature)] != null;
  }

  // Do not forget to add the test category specific field to resetFields()!!!
  get(
    feature: Feature,
    defaultValue: any = null,
    decode: boolean = false
  ): any {
    return this._map.has(feature) ?
      this._map.get(feature) :
      this._fetch(feature, defaultValue, decode);
  }

  set(
    feature: Feature,
    value: any,
    encode: boolean = false
  ): void {

    this._map.set(feature, value);

    const key: string = PersistentStateKey.get(feature);
    const strValue: string = encode ?
      this.encode(JSON.stringify(value)) :
      JSON.stringify(value);

    localStorage[key] = strValue;

    // Notify about the change.
    this.valueChanged$$.next(feature);
  }

  get valueChanged$(): Observable<string> {
    return this.valueChanged$$.asObservable();
  }

  private _fetch(
    feature: Feature,
    defaultValue: any,
    decode: boolean
  ): any {

    let strValue = localStorage[PersistentStateKey.get(feature)];

    this._map.set(
      feature,
      strValue == null ?
        defaultValue :
        JSON.parse(decode ?
          this.decode(strValue) :
          strValue
        )
    );

    return this._map.get(feature);
  }

  private decode(value: string): string {
    let arrValue = JSON.parse(value);
    let result = '';
    for (let i = 0; i < arrValue.length; i++) {
      result = result.concat(String.fromCharCode(arrValue[i]));
    }
    return result;

  }

  private encode(value: string): string {

    let result = new Array(value.length);
    for (let i = 0; i < value.length; i++) {
      result[i] = value.charCodeAt(i);
    }

    return JSON.stringify(result);
  }
}

