<div class="address-container">

    <mat-form-field>
        <mat-label>{{'address_panel.first_name' | translate}}</mat-label>

        <input name="first_name" matInput [(ngModel)]="address().firstName">

        @if (address().firstName.length > 0) {
        <button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="address().firstName=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'address_panel.last_name' | translate}}</mat-label>

        <input name="last_name" matInput [(ngModel)]="address().lastName">

        @if (address().lastName.length > 0) {
        <button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="address().lastName=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'address_panel.street' | translate}}</mat-label>

        <input name="street" matInput [(ngModel)]="address().street">

        @if (address().street.length > 0) {
        <button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="address().street=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'address_panel.city' | translate}}</mat-label>

        <input name="city" matInput [(ngModel)]="address().city">

        @if (address().city.length > 0) {
        <button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="address().city=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'address_panel.zip' | translate}}</mat-label>

        <input name="zip" matInput [(ngModel)]="address().zip">

        @if (address().zip.length > 0) {
        <button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="address().zip=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'address_panel.country' | translate}}</mat-label>

        <input name="country" matInput [(ngModel)]="address().country">

        @if (address().country.length > 0) {
        <button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="address().country=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'address_panel.company' | translate}}</mat-label>

        <input name="company" matInput [(ngModel)]="address().company">

        @if (address().company.length > 0) {
        <button type="button"  matSuffix mat-icon-button aria-label="Clear" (click)="address().company=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

</div>