<server-select></server-select>

<product-select (productSelected)="onProductSelected($event)"></product-select>

<mat-form-field>
    <mat-label>{{'create_multiple.user_id_template_label' | translate}}</mat-label>
    <input matInput [(ngModel)]="userIdTemplate" [formControl]="userIdTemplateFormControl" [errorStateMatcher]="matcher"
        placeholder="{{'create_multiple.user_id_template_placeholder' | translate}}">
    <mat-hint align="start">{{'create_multiple.user_id_template_hint' | translate}}</mat-hint>
    @if (userIdTemplate().length > 0) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="userIdTemplate.set('')">
        <mat-icon>close</mat-icon>
    </button>
    }
    @if (userIdTemplateFormControl.hasError('required')) {
    <mat-error>{{'create_multiple.user_id_template_required' | translate}}</mat-error>
    }

</mat-form-field>

<mat-form-field>
    <mat-label>{{'create_multiple.user_id_number_length_label' | translate}}</mat-label>
    <input type="number" matInput [(ngModel)]="userIdNumberLength" [formControl]="userIdNumberLengthFormControl"
        [errorStateMatcher]="matcher">
    <mat-hint align="start">{{'create_multiple.user_id_number_length_hint' | translate}}</mat-hint>
    @if (userIdNumberLengthFormControl.hasError('required')) {
    <mat-error>{{'create_multiple.user_id_number_length_required' | translate}}</mat-error>
    }
    @if (userIdNumberLengthFormControl.hasError('min')) {
    <mat-error>{{'create_multiple.user_id_number_length_min' | translate}}</mat-error>
    }

</mat-form-field>

<mat-form-field>
    <mat-label>{{'create_multiple.first_number_label' | translate}}</mat-label>
    <input type="number" matInput [(ngModel)]="firstNumber" [formControl]="firstNumberFormControl"
        [errorStateMatcher]="matcher">
    <mat-hint align="start">{{'create_multiple.first_number_hint' | translate}}</mat-hint>
    @if (firstNumberFormControl.hasError('required')) {
    <mat-error>{{'create_multiple.first_number_required' | translate}}</mat-error>
    }
    @if (firstNumberFormControl.hasError('min')) {
    <mat-error>{{'create_multiple.first_number_min' | translate}}</mat-error>
    }
</mat-form-field>

<mat-form-field>
    <mat-label>{{'create_multiple.last_number_label' | translate}}</mat-label>
    <input type="number" matInput [(ngModel)]="lastNumber" [formControl]="lastNumberFormControl()"
        [errorStateMatcher]="matcher">
    <mat-hint align="start">{{'create_multiple.last_number_hint' | translate}}</mat-hint>
    @if (lastNumberFormControl().hasError('required')) {
    <mat-error>{{'create_multiple.last_number_required' | translate}}</mat-error>
    }
    @if (lastNumberFormControl().hasError('min')) {
    <mat-error>{{'create_multiple.last_number_min' | translate}}</mat-error>
    }
</mat-form-field>