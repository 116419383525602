import { Component, model, output, signal } from '@angular/core';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ServerSelectComponent } from '../../server-select/server-select.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'user-id-request',
    imports: [
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinner,
        ServerSelectComponent,
        TranslateModule
    ],
    templateUrl: './user-id-request.component.html',
    styleUrl: './user-id-request.component.css'
})
export class UserIdRequestComponent {

  processing = model(false); // Tracks whether the request is in progress.
  submitRequest = output<string>();

  hidePassword = signal<boolean>(true);
  productId = signal<string>('');
  userId = signal<string>('');


  onProductSelected(product: ISapiProduct | undefined) {
    if (product) {
      this.productId.set(product.id);
    }
  }

  onSubmit(): void {
    if (this.processing()) return;

    this.processing.set(true); // Disable inputs and show the spinner.

    this.submitRequest.emit(this.userId().trim());
  }
}
